














import { Component, Prop, Vue } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";

@Component({
  components: { SaveButton, AbortButton, FscSimpleCard },
})
export default class FormCard extends Vue {
  public name = "FormCard";

  @Prop({ type: String })
  public translationKey?: any;

  @Prop({ type: String })
  public value?: any;

  public modelValue: any = "";
}
