
























































































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import FscMultiselect from "@/components/FscMultiselect.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SendMailButton from "@/components/Button/SendMailButton.vue";
import Tinymce from "@/components/Editor/Tinymce.vue";
import Validation from "@/components/Validation.vue";
import { Validations } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";

@Component({
  components: { Validation, FscSimpleCard, FscMultiselect, AbortButton, SendMailButton, Tinymce },
})
export default class TemplateForm extends Vue {
  public name = "TemplateForm";
  @Prop({ type: Boolean, default: false })
  public formIsNew?: any;

  @Prop({ type: Array, default: () => [] })
  public templateCategories?: Array<any>;

  @Prop({ type: Object, default: () => ({}) })
  public getSelectedTemplate: any;

  public template: any = {
    templateCategoryId: 0,
    templateCategory: {},
    id: 0,
    subject: "",
    body: "",
    name: "",
  };
  public selectedTemplateCategory: any = {};

  @Validations()
  public validations() {
    return {
      template: {
        body: { required },
        subject: { required },
        name: { required },
      },
    };
  }

  protected get isValidData() {
    const values = [null, "", undefined];
    let flag = true;
    Object.keys(this.template).forEach((key) => {
      if (values.includes(this.template[key])) {
        flag = false;
        return flag;
      }
    });
    return flag;
  }

  public setData() {
    this.template = this.getSelectedTemplate;
    this.selectedTemplateCategory = this.templateCategories?.find((category) => category.id == this.template.templateCategoryId);
  }

  public mounted() {
    if (!this.formIsNew) {
      this.setData();
    } else {
      this.selectedTemplateCategory = {};
    }
  }

  public updated() {
    this.setData();
  }

  public onSelectEmailTemplateCategory(e: any): void {
    this.template.templateCategoryId = e.id;
  }

  public onAbort() {
    this.$emit("close");
  }

  public onTemplateSave(): void {
    this.$v.$touch();

    if (this.$v.$invalid) return;
    this.$emit("confirm-data-set", { template: this.template, editMode: !this.formIsNew });
    this.template = {};
  }

  @Watch("getSelectedTemplate", { immediate: true, deep: true })
  public onSelectedTemplateChange(v: any) {
    this.setData();
  }
}
