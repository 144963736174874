




















import { Component, Prop, Vue } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";

@Component({
  components: { FscSimpleCard, FscPageHeader },
})
export default class TemplateCategoryItem extends Vue {
  public name = "TemplateCategoryItem";
  protected currentCategory = 0;

  @Prop({ type: Object })
  public templateCategory!: any;

  public visible = false;
  public selectedRow: any = {};

  public fields = [
    {
      key: "name",
      label: "",
      sortable: false,
    },
  ];
  public mounted() {
    this.$root.$on("active-category", (e: any) => {
      this.currentCategory = e;
    });
  }
  public onRowClicked(template: any): void {
    this.selectedRow = template.id;
    this.$root.$emit("template-form-show", template);
  }
  public setActiveCategory(e: any) {
    const id = this.templateCategory.id;
    this.$root.$emit("active-category", id);
  }
  public toggleVisible() {
    this.visible = !this.visible;
  }
  public rowClass(item: any, type: any): any {
    if (item && type === "row") {
      return item.id === this.selectedRow && this.currentCategory === item.templateCategoryId ? " table-row-selected" : "";
    }
  }
}
